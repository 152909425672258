// ====== APPLICATION CONSTANTS ====== //
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const VERSION = process.env.REACT_APP_VERSION;
export const EXTERNAL_URL = "https://tenant.rent-savvy.com/register";
export const EMAIL_ADDRESS = "info@rent-savvy.com";
export const PHONE_NO = "+971 987 654 3210";
export const ADDRESS = "Some Rd., 12th St, ABC Layout, City";
export const RECAPTCHA_SITE_KEY = "6LceSVsqAAAAAPCK1-Syh6Kq26CzkhrVZ0RYqxA3";
export const RECAPTCHA_SECRET_KEY = "6LceSVsqAAAAAOY2b-pvJsQcvLobbysCFfq7ZCJm";
export const MENU_ITEMS = [
	{
		title: "Home",
		url: "/",
	},
	{
		title: "About",
		url: "/about",
	},
	{
		title: "Why Choose Us",
		url: "/why-choose-us",
	},
	{
		title: "How It Works",
		url: "/how-it-works",
	},
	{
		title: "FAQs",
		url: "/faq",
	},
	{
		title: "Contact",
		url: "/contact",
	},
];
export const FACEBOOK_URL = "https://facebook.com";
export const TWITTER_URL = "https://twitter.com";
export const WHATSAPP_URL = "https://whatsapp.com";
export const LINKEDIN_URL = "https://linkedin.com";
export const INSTAGRAM_URL = "https://instagram.com";

/* =================================================== 
	Passcodes
=================================================== */

export const ALLOWED_PASSCODES = [
	"Rent5@vvyPasscode",
	"R3n+5@vvyPW",
	"W3b51T3PW",
	"RentSavvyP@55w0rd",
	"RSP@55w0rd",
];
