import { CiLocationOn, CiMail, CiPhone } from "react-icons/ci";
import { ADDRESS, EMAIL_ADDRESS, PHONE_NO } from "../../utilities/constants";
import ContactForm from "./ContactForm";

const ContactSection = () => {
	return (
		<section className='contact'>
			<div className='container'>
				<div className='row flex'>
					<div className='col-1-2 left'>
						<h4 className='color-primary'>Email us</h4>
						<br />
						<hr className='sep' />
						<br />
						<p>
							Don't hesitate to send us a message – we're all
							ears!
						</p>
						<br />
						<ContactForm />
					</div>
					<div className='col-1-2 right'>
						<div className='txt'>
							<h4 className='color-primary'>Contact us</h4>
							<br />
							<hr className='sep' />
							<br />
							<p>
								Got questions about renting or need assistance?
								At Rent-Savvy, we're here to help you make the
								best rental decisions. Whether you need advice
								or have any concerns, reach out to our support
								team for prompt and friendly assistance.
							</p>
							<br />
							<p>
								Contact us via email or phone, and we'll get
								back to you as soon as possible.
							</p>
							<br />
							<div
								className='flex center-left'
								style={{ gap: "20px" }}>
								<CiMail
									style={{
										height: "24px",
										width: "24px",
										display: "block",
									}}
									className='color-secondary'
								/>
								<div
									style={{
										flex: 1,
									}}>
									<p className='f-w-700 color-primary'>
										Email
									</p>
									<p>
										<a href={`mailto:${EMAIL_ADDRESS}`}>
											{EMAIL_ADDRESS}
										</a>
									</p>
								</div>
							</div>
							<br />
							<div
								className='flex center-left'
								style={{ gap: "20px" }}>
								<CiPhone
									style={{
										height: "24px",
										width: "24px",
										display: "block",
									}}
									className='color-secondary'
								/>
								<div
									style={{
										flex: 1,
									}}>
									<p className='f-w-700 color-primary'>
										Phone
									</p>
									<p>
										<a href={`tel:${PHONE_NO}`}>
											{PHONE_NO}
										</a>
									</p>
								</div>
							</div>
							<br />
							<div
								className='flex center-left'
								style={{ gap: "20px" }}>
								<CiLocationOn
									style={{
										height: "24px",
										width: "24px",
										display: "block",
									}}
									className='color-secondary'
								/>
								<div
									style={{
										flex: 1,
									}}>
									<p className='f-w-700 color-primary'>
										Address
									</p>
									<p>{ADDRESS}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ContactSection;
