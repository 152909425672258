import { EXTERNAL_URL } from "../../utilities/constants";
import IllustrationImage from "../../images/pages/home/illustration.svg";
import ButtonTextIcon from "../ui/ButtonTextIcon";
import { CiLogin } from "react-icons/ci";

const Introduction = () => {
	return (
		<section className='introduction' id='introduction'>
			<div className='container'>
				<div className='row flex center-center'>
					<div className='col-1-2 left flex center-center'>
						<img
							alt='rent-savvy.com'
							className='col-1-1 height-auto dis-b'
							src={IllustrationImage}
						/>
					</div>
					<div className='col-1-2 right'>
						<div className='txt'>
							<h2 className='color-primary'>Introduction</h2>
							<br />
							<hr className='sep' />
							<br />
							<p>
								Rent-Savvy is a cutting-edge platform built to
								transform the rental experience for both tenants
								and landlords. Whether you’re searching for your
								ideal home or managing multiple properties,
								Rent-Savvy offers a seamless and efficient
								solution that simplifies every step of the
								process.
							</p>
							<br />
							<p>
								Tenants can explore a wide range of verified
								listings, communicate securely with landlords,
								and apply for rentals online—eliminating the
								hassle of paperwork. For landlords, Rent-Savvy
								provides intuitive tools to list properties,
								screen tenants, and manage leases all in one
								place.
							</p>
							<br />
							<p>
								Our mission is to make renting a stress-free and
								transparent experience, ensuring convenience,
								security, and peace of mind for everyone
								involved. With Rent-Savvy, renting has never
								been easier.
							</p>
							<div className='btn'>
								<a href={EXTERNAL_URL}>
									<ButtonTextIcon
										title='Register Now'
										icon={<CiLogin />}
									/>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Introduction;
