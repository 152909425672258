import { useEffect } from "react";
import PopUpModal from "../common/PopUpModal";
import { useOutsideClick } from "../../utilities/hooks";

const VideoPlayer = ({ play, close }) => {
	const handleKeyDown = (event) => {
		if (event.key === "Escape") {
			close();
		}
	};

	useEffect(() => {
		const body = document.getElementsByTagName("body")[0];
		if (play) {
			body.style.overflow = "hidden";
			window.addEventListener("keydown", handleKeyDown);
		} else {
			body.style.overflow = "auto";
			window.removeEventListener("keydown", handleKeyDown);
		}
	}, [play]);

	const [box] = useOutsideClick({
		state: true,
		callBack: () => {
			close();
		},
	});

	if (!play) {
		return <></>;
	}

	return (
		<PopUpModal>
			<section id='video-player'>
				<video
					ref={box}
					playsInline={true}
					controls={true}
					autoPlay={true}
					muted={false}
					loop={true}
					controlsList='nodownload'>
					<source src={play} type='video/mp4' />
				</video>
				<div className='close' onClick={() => close()}>
					<svg
						clipRule='evenodd'
						fillRule='evenodd'
						strokeLinejoin='round'
						strokeMiterlimit='2'
						viewBox='0 0 24 24'
						xmlns='http://www.w3.org/2000/svg'>
						<path d='m12 10.93 5.719-5.72c.146-.146.339-.219.531-.219.404 0 .75.324.75.749 0 .193-.073.385-.219.532l-5.72 5.719 5.719 5.719c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.385-.073-.531-.219l-5.719-5.719-5.719 5.719c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l5.719-5.719-5.72-5.719c-.146-.147-.219-.339-.219-.532 0-.425.346-.749.75-.749.192 0 .385.073.531.219z' />
					</svg>
				</div>
			</section>
		</PopUpModal>
	);
};

export default VideoPlayer;
