import { CiCircleQuestion, CiLogin, CiPaperplane } from "react-icons/ci";
import LandingImage from "../../images/pages/home/landing-bg.jpg";
import RentSavvyLogo from "../common/RentSavvyLogo";
import ButtonTextIcon from "../ui/ButtonTextIcon";
import { NavLink } from "react-router-dom";
import { EXTERNAL_URL } from "../../utilities/constants";

const HeroSection = () => {
	return (
		<section
			className='hero pos-rel cover-img flex center-center'
			style={{
				backgroundImage: `url(${LandingImage})`,
			}}>
			<div className='txt'>
				<div className='logo'>
					<RentSavvyLogo />
				</div>
				<div className='flex title'>
					<h1 className='f-w-700 color-white l-h-1'>
						A smarter way to rent
					</h1>
				</div>
				<h5
					className='f-w-300 l-h-1-2 color-white t-a-c description'
					style={{
						color: "rgba(255,255,255,0.86)",
						lineHeight: 1.5,
					}}>
					Rent-Savvy is a cutting-edge platform built to transform the
					rental experience for both tenants and landlords.
				</h5>
				<div className='btns flex center-center'>
					<a href={EXTERNAL_URL} target='_blank' rel='noreferrer'>
						<ButtonTextIcon
							title='Register now'
							icon={<CiLogin />}
						/>
					</a>
					<NavLink to='/how-it-works'>
						<ButtonTextIcon
							className='light'
							title='How it Works'
							icon={<CiCircleQuestion />}
						/>
					</NavLink>
				</div>
			</div>
			<div className='arr flex center-center'>
				<a className='dis-b' href='#introduction'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='63.048'
						height='99.458'
						viewBox='0 0 63.048 99.458'>
						<path
							d='M123.019-511.607c-8.174.934-14.149,3.7-19.54,8.992A31.144,31.144,0,0,0,94.215-483.6c-.253,2.374-.253,41.026,0,43.4a31.285,31.285,0,0,0,9.069,18.859,31.829,31.829,0,0,0,17.5,8.894,45.934,45.934,0,0,0,9.536,0A31.6,31.6,0,0,0,156.883-440.2c.253-2.374.253-41.026,0-43.4a31.107,31.107,0,0,0-8.914-18.664,30.856,30.856,0,0,0-17.263-8.992A47.571,47.571,0,0,0,123.019-511.607Zm.778,6.734v3.367l-1.07.467a7.186,7.186,0,0,0-3.27,3.27c-.409.915-.428,1.129-.428,7.649v6.714l.487.973a7.756,7.756,0,0,0,3.367,3.309l.915.35v6.948H97.329v-4.924c0-6.578.331-9.05,1.713-12.981a29.03,29.03,0,0,1,11.191-14.091c3.737-2.394,9.3-4.3,12.748-4.379l.817-.019Zm6.637-3.036a28.314,28.314,0,0,1,22.537,21.194c.623,2.511.8,4.749.8,9.965v4.924H127.3v-6.948l.915-.35a7.756,7.756,0,0,0,3.367-3.309l.487-.973v-6.714c0-6.52-.019-6.734-.428-7.649a7.186,7.186,0,0,0-3.27-3.27l-1.07-.467v-6.714h.642A21.468,21.468,0,0,1,130.434-507.909ZM127.125-498a3.546,3.546,0,0,1,1.635,2.1c.058.35.1,3.172.058,6.286l-.058,5.663-.545.7a3.085,3.085,0,0,1-2.666,1.343,3.085,3.085,0,0,1-2.666-1.343l-.545-.7-.058-5.761a53.736,53.736,0,0,1,.136-6.442A3.38,3.38,0,0,1,127.125-498Zm26.585,43.731c-.058,13.954-.078,14.3-.506,16.212a27.649,27.649,0,0,1-8.252,14.83,27.562,27.562,0,0,1-17.029,7.668,28.8,28.8,0,0,1-15.044-2.861,32.235,32.235,0,0,1-6.734-4.807,27.649,27.649,0,0,1-8.252-14.83c-.428-1.907-.448-2.258-.506-16.212l-.078-14.246h56.479Z'
							transform='translate(-94.025 511.657)'
						/>
					</svg>
				</a>
			</div>
		</section>
	);
};

export default HeroSection;
