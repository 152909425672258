import { CiChat1, CiLogin, CiRead } from "react-icons/ci";
import ButtonTextIcon from "../ui/ButtonTextIcon";
import { NavLink } from "react-router-dom";

const QuickIntro = ({ showButton }) => {
	return (
		<section className='about-us of-hid pos-rel'>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='90.396'
				height='82.351'
				viewBox='0 0 90.396 82.351'
				className='right'>
				<g transform='translate(-288.14 -169.729)'>
					<path
						d='M276.138,252.079V334.43h57.775s18.088-.5,24.874-10.241,12.908-27.337,0-41.4-31.156-30.71-31.156-30.71Z'
						transform='translate(654.483 504.159) rotate(180)'
						fill='#5dc6b3'
					/>
					<path
						d='M327.624,252.079V190.464l25.5,21.287V252.08Z'
						fill='#1e4ccb'
					/>
					<path
						d='M327.624,190.464l25.4-19.87,25.512,19.87L353.027,211.9Z'
						fill='#3b86b5'
					/>
				</g>
			</svg>
			<div className='container'>
				<div className='row'>
					<div className='col-4-5'>
						<h1 className='color-primary'>
							At{" "}
							<span className='color-secondary'>Rent-Savvy</span>,
							we are passionate about redefining the{" "}
							<span className='color-secondary'>
								rental experience
							</span>
							.
						</h1>
						<br />
						<h4
							className='color-primary f-w-300 l-h-1-5'
							style={{
								textWrap: "balance",
							}}>
							Founded by a team of{" "}
							<span className='f-w-600 color-secondary'>
								industry experts
							</span>
							, our mission is to provide a user-friendly platform
							that streamlines the rental process, making it{" "}
							<span className='f-w-600 color-secondary'>
								accessible and efficient
							</span>
							.
						</h4>
						{showButton && (
							<div className='btn'>
								<NavLink to='/about'>
									<ButtonTextIcon
										title='More About Us'
										icon={<CiChat1 />}
									/>
								</NavLink>
							</div>
						)}
					</div>
				</div>
			</div>
		</section>
	);
};

export default QuickIntro;
