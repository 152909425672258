import CommonLanding from "../components/common/CommonLanding";
import GotQuestion from "../components/faq/GotQuestion";
import QuestionAnswers from "../components/faq/QuestionAnswers";

const FAQs = () => {
	return (
		<>
			<CommonLanding
				title='FAQs'
				subtitle='Frequently asked questions.'
			/>
			<QuestionAnswers />
			<GotQuestion />
		</>
	);
};

export default FAQs;
