import { useParallax } from "react-scroll-parallax";

const Marquee = () => {
	const parallax1 = useParallax({
		onProgressChange: (progress) => {
			if (parallax1.ref.current) {
				parallax1.ref.current.style.setProperty(
					"--progress",
					progress.toString()
				);
			}
		},
	});

	const parallax2 = useParallax({
		onProgressChange: (progress) => {
			if (parallax2.ref.current) {
				parallax2.ref.current.style.setProperty(
					"--progress",
					progress.toString()
				);
			}
		},
	});

	const parallax3 = useParallax({
		onProgressChange: (progress) => {
			if (parallax3.ref.current) {
				parallax3.ref.current.style.setProperty(
					"--progress",
					progress.toString()
				);
			}
		},
	});

	const parallax4 = useParallax({
		onProgressChange: (progress) => {
			if (parallax4.ref.current) {
				parallax4.ref.current.style.setProperty(
					"--progress",
					progress.toString()
				);
			}
		},
	});

	return (
		<section className='marquee'>
			<ul>
				<li
					ref={parallax1.ref}
					style={{
						marginLeft: `calc(1200px * var(--progress))`,
					}}
					className='rs'>
					rent-savvy
				</li>
				<li className='sp'>&nbsp;</li>
				<li>a</li>
				<li className='smart'>smarter</li>
				<li>way</li>
				<li>to</li>
				<li>rent</li>
				<li className='sp'>&nbsp;</li>
				<li className='rs'>rent-savvy</li>
				<li className='sp'>&nbsp;</li>
				<li>a</li>
				<li className='smart'>smarter</li>
				<li>way</li>
				<li>to</li>
				<li>rent</li>
				<li className='sp'>&nbsp;</li>
				<li className='rs'>rent-savvy</li>
				<li className='sp'>&nbsp;</li>
				<li>a</li>
				<li className='smart'>smarter</li>
				<li>way</li>
				<li>to</li>
				<li>rent</li>
				<li className='sp'>&nbsp;</li>
				<li className='rs'>rent-savvy</li>
				<li className='sp'>&nbsp;</li>
				<li>a</li>
				<li className='smart'>smarter</li>
				<li>way</li>
				<li>to</li>
				<li>rent</li>
			</ul>
			<ul>
				<li
					ref={parallax2.ref}
					style={{
						marginRight: `calc(1000px * var(--progress))`,
					}}>
					apartment
				</li>
				<li className='sp'>&nbsp;</li>
				<li>town house</li>
				<li className='sp'>&nbsp;</li>
				<li>villa</li>
				<li className='sp'>&nbsp;</li>
				<li>apartment</li>
				<li className='sp'>&nbsp;</li>
				<li>town house</li>
				<li className='sp'>&nbsp;</li>
				<li>villa</li>
				<li className='sp'>&nbsp;</li>
				<li>apartment</li>
				<li className='sp'>&nbsp;</li>
				<li>town house</li>
				<li className='sp'>&nbsp;</li>
				<li>villa</li>
				<li className='sp'>&nbsp;</li>
				<li>apartment</li>
				<li className='sp'>&nbsp;</li>
				<li>town house</li>
				<li className='sp'>&nbsp;</li>
				<li>villa</li>
				<li className='sp'>&nbsp;</li>
				<li>apartment</li>
				<li className='sp'>&nbsp;</li>
				<li>town house</li>
				<li className='sp'>&nbsp;</li>
				<li>villa</li>
			</ul>
			<ul>
				<li
					ref={parallax3.ref}
					style={{
						marginLeft: `calc(800px * var(--progress))`,
					}}
					className='rs'>
					rent-savvy
				</li>
				<li className='sp'>&nbsp;</li>
				<li>a</li>
				<li className='smart'>smarter</li>
				<li>way</li>
				<li>to</li>
				<li>rent</li>
				<li className='sp'>&nbsp;</li>
				<li className='rs'>rent-savvy</li>
				<li className='sp'>&nbsp;</li>
				<li>a</li>
				<li className='smart'>smarter</li>
				<li>way</li>
				<li>to</li>
				<li>rent</li>
				<li className='sp'>&nbsp;</li>
				<li className='rs'>rent-savvy</li>
				<li className='sp'>&nbsp;</li>
				<li>a</li>
				<li className='smart'>smarter</li>
				<li>way</li>
				<li>to</li>
				<li>rent</li>
				<li className='sp'>&nbsp;</li>
				<li className='rs'>rent-savvy</li>
				<li className='sp'>&nbsp;</li>
				<li>a</li>
				<li className='smart'>smarter</li>
				<li>way</li>
				<li>to</li>
				<li>rent</li>
			</ul>
			<ul>
				<li
					ref={parallax4.ref}
					style={{
						marginRight: `calc(600px * var(--progress))`,
					}}>
					apartment
				</li>
				<li className='sp'>&nbsp;</li>
				<li>town house</li>
				<li className='sp'>&nbsp;</li>
				<li>villa</li>
				<li className='sp'>&nbsp;</li>
				<li>apartment</li>
				<li className='sp'>&nbsp;</li>
				<li>town house</li>
				<li className='sp'>&nbsp;</li>
				<li>villa</li>
				<li className='sp'>&nbsp;</li>
				<li>apartment</li>
				<li className='sp'>&nbsp;</li>
				<li>town house</li>
				<li className='sp'>&nbsp;</li>
				<li>villa</li>
				<li className='sp'>&nbsp;</li>
				<li>apartment</li>
				<li className='sp'>&nbsp;</li>
				<li>town house</li>
				<li className='sp'>&nbsp;</li>
				<li>villa</li>
				<li className='sp'>&nbsp;</li>
				<li>apartment</li>
				<li className='sp'>&nbsp;</li>
				<li>town house</li>
				<li className='sp'>&nbsp;</li>
				<li>villa</li>
			</ul>
		</section>
	);
};

export default Marquee;
