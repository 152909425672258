const FacebookIcon = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'>
			<path d='M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm3,8H13.65c-.538,0-.65.221-.65.778V10h2l-.209,2H13v7H10V12H8V10h2V7.692C10,5.923,10.931,5,13.029,5H15Z' />
		</svg>
	);
};

const TwitterIcon = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'>
			<path d='M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm6.066,9.645A8.168,8.168,0,0,1,5.5,16.9a5.778,5.778,0,0,0,4.252-1.189,2.879,2.879,0,0,1-2.684-1.995,2.881,2.881,0,0,0,1.3-.049,2.876,2.876,0,0,1-2.3-2.853,2.865,2.865,0,0,0,1.3.359,2.877,2.877,0,0,1-.889-3.835,8.153,8.153,0,0,0,5.92,3,2.874,2.874,0,0,1,4.9-2.62,5.729,5.729,0,0,0,1.824-.7A2.884,2.884,0,0,1,17.85,8.609,5.73,5.73,0,0,0,19.5,8.156,5.765,5.765,0,0,1,18.066,9.645Z' />
		</svg>
	);
};

const WhatsappIcon = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'>
			<path d='M12.031,6.172a5.777,5.777,0,0,0-4.749,9.053L6.7,17.353l2.182-.573A5.773,5.773,0,0,0,17.8,11.943,5.764,5.764,0,0,0,12.031,6.172Zm3.392,8.244a1.784,1.784,0,0,1-1.17.824,2.371,2.371,0,0,1-1.092-.069,10.063,10.063,0,0,1-.988-.365,7.735,7.735,0,0,1-2.961-2.617A3.37,3.37,0,0,1,8.5,10.4,1.943,1.943,0,0,1,9.111,8.95a.638.638,0,0,1,.462-.217l.332.006c.106.005.249-.04.39.3s.491,1.2.534,1.287a.32.32,0,0,1,.014.3,1.2,1.2,0,0,1-.173.289l-.26.3c-.087.086-.177.18-.076.354a5.223,5.223,0,0,0,.964,1.2,4.7,4.7,0,0,0,1.394.86c.173.086.274.072.376-.043s.433-.506.549-.68.231-.145.39-.087,1.011.477,1.184.564.289.13.332.2a1.423,1.423,0,0,1-.1.824ZM12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm.029,18.88a6.944,6.944,0,0,1-3.318-.844L5.034,19l.984-3.6a6.937,6.937,0,1,1,6.011,3.475Z' />
		</svg>
	);
};

const InstagramIcon = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'>
			<path d='M14.829,6.3c-.738-.034-.96-.04-2.829-.04s-2.09.007-2.828.04A2.63,2.63,0,0,0,6.3,9.172c-.033.738-.041.959-.041,2.828s.008,2.09.041,2.829a2.631,2.631,0,0,0,2.87,2.87c.737.033.959.041,2.828.041s2.091-.007,2.829-.041a2.631,2.631,0,0,0,2.87-2.87c.033-.738.04-.96.04-2.829s-.007-2.09-.04-2.828a2.632,2.632,0,0,0-2.87-2.87ZM12,15.6A3.595,3.595,0,1,1,15.6,12,3.595,3.595,0,0,1,12,15.6ZM15.737,9.1a.84.84,0,1,1,.84-.84A.84.84,0,0,1,15.737,9.1Zm-1.4,2.9A2.333,2.333,0,1,1,12,9.667,2.333,2.333,0,0,1,14.333,12ZM12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm6.958,14.886a3.864,3.864,0,0,1-4.071,4.072C14.14,18.992,13.9,19,12,19s-2.139-.008-2.886-.042a3.865,3.865,0,0,1-4.072-4.072C5.008,14.14,5,13.9,5,12s.008-2.139.042-2.886A3.863,3.863,0,0,1,9.114,5.043C9.861,5.008,10.1,5,12,5s2.14.008,2.887.043a3.863,3.863,0,0,1,4.071,4.071C18.992,9.861,19,10.1,19,12S18.992,14.14,18.958,14.886Z' />
		</svg>
	);
};

const LinkedinIcon = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'>
			<path d='M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0ZM10,16H8V10h2ZM9,9.109A1.109,1.109,0,1,1,10.1,8,1.1,1.1,0,0,1,9,9.109ZM17,16H15V13.139c0-1.881-2-1.722-2,0V16H11V10h2v1.093c.872-1.616,4-1.736,4,1.548Z' />
		</svg>
	);
};

export { FacebookIcon, TwitterIcon, WhatsappIcon, InstagramIcon, LinkedinIcon };
