import CommonLanding from "../components/common/CommonLanding";
import Convinced from "../components/why-choose-us/Convinced";
import WhyChooseUsSection from "../components/why-choose-us/WhyChooseUsSection";

const WhyChooseUs = () => {
	return (
		<>
			<CommonLanding
				title='Why Choose Us'
				subtitle='With Rent-Savvy.com, you gain access to a wide selection of verified rental listings in Dubai, ensuring a safe and smooth experience.'
			/>
			<WhyChooseUsSection />
			<Convinced />
		</>
	);
};

export default WhyChooseUs;
