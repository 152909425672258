import CommonLanding from "../components/common/CommonLanding";
import HowItWorksSection from "../components/how-it-works/HowItWorksSection";
import Video from "../components/how-it-works/Video";
import Convinced from "../components/why-choose-us/Convinced";

const HowItWorks = () => {
	return (
		<>
			<CommonLanding
				title='How it Works'
				subtitle='Rent-Savvy makes renting simple and hassle-free.'
			/>
			<HowItWorksSection />
			<Video />
			<Convinced />
		</>
	);
};

export default HowItWorks;
