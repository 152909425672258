import { useState } from "react";
import LandingImage from "../../images/pages/home/landing-bg.jpg";
import VideoMP4 from "../../images/main-video.mp4";
import VideoPlayer from "./VideoPlayer";
import ButtonTextIcon from "../ui/ButtonTextIcon";
import { CiPlay1 } from "react-icons/ci";

const Video = () => {
	const [play, setPlay] = useState(false);

	const startPlay = () => {
		setPlay(VideoMP4);
	};

	return (
		<>
			<VideoPlayer play={play} close={() => setPlay(false)} />
			<section className='video'>
				<div className='container'>
					<div className='row flex center-left'>
						<div className='col-2-5 left'>
							<div className='txt'>
								<h1 className='color-primary'>How it Works</h1>
								<br />
								<hr className='sep' />
								<br />
								<h4 className='color-light f-w-300'>
									Watch how{" "}
									<span className='color-secondary f-w-600'>
										Rent-Savvy
									</span>{" "}
									works!
								</h4>
								<br />
								<p>
									From browsing listings to managing
									properties, our step-by-step walkthrough
									will show you how Rent-Savvy simplifies the
									rental process for tenants and landlords
									alike.
								</p>
								<br />
								<p>
									Dive in and discover the smarter way to
									rent!
								</p>
								<div className='btn'>
									<ButtonTextIcon
										title='Watch Video'
										icon={<CiPlay1 />}
										onClick={() => startPlay()}
									/>
								</div>
							</div>
						</div>
						<div className='col-3-5 right'>
							<div
								className='box cover-img'
								onClick={() => startPlay()}
								style={{
									backgroundImage: `url(${LandingImage})`,
								}}>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									className='play transition'>
									<path
										d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-3 17v-10l9 5.146-9 4.854z'
										fill='white'
									/>
								</svg>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Video;
