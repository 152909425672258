import CommonLanding from "../components/common/CommonLanding";
import QuickIntro from "../components/about/QuickIntro";
import ThePeople from "../components/about/ThePeople";
import MissionVision from "../components/about/MissionVision";

const About = () => {
	return (
		<>
			<CommonLanding
				title='About Us'
				subtitle='Discover the people behind the idea.'
			/>
			<QuickIntro />
			{/* <ThePeople /> */}
			<MissionVision />
		</>
	);
};

export default About;
