import { useState, useRef } from "react";
import { Formik, Field, Form } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import { RECAPTCHA_SITE_KEY } from "../../utilities/constants";
import ButtonTextIcon from "../ui/ButtonTextIcon";
import { CiLogin } from "react-icons/ci";
import LoaderIcon from "../ui/LoaderIcon";
import { sleep } from "../../utilities/helpers";

const ContactForm = () => {
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState("");
	const recaptchaRef = useRef();
	const [isVerified, setIsVerified] = useState(false);

	const FormValidation = Yup.object().shape({
		fullname: Yup.string().required("Name is required!"),
		email: Yup.string().email().required("Email is required!"),
		phone: Yup.string()
			.matches(/^[0-9]*$/, "Only numbers allowed!")
			.required("Phone number is required!")
			.min(10, "Phone number must be at least 10 digits long!")
			.max(10, "Phone number must be at most 10 digits long!"),
		subject: Yup.string().required("Subject is required!"),
		message: Yup.string().required("Message is required!"),
		token: Yup.string().required("Token is required!"),
	});

	const initial = {
		fullname: process.env.REACT_APP_MODE === "DEV" ? "Prabhat Dawadi" : "",
		email:
			process.env.REACT_APP_MODE === "DEV" ? "hi@prabhatdawadi.com" : "",
		phone: process.env.REACT_APP_MODE === "DEV" ? "9066641830" : "",
		subject: process.env.REACT_APP_MODE === "DEV" ? "Regular Message" : "",
		message:
			process.env.REACT_APP_MODE === "DEV" ? "Just saying hello." : "",
		token: "",
	};

	return (
		<Formik
			initialValues={initial}
			validationSchema={FormValidation}
			onSubmit={async (values, { resetForm }) => {
				setLoading(true);
				setStatus("");
				console.log(JSON.stringify(values));
				await sleep(4000);
				setLoading(false);
				setIsVerified(false);
				resetForm();
				setStatus(
					"We have successfully received your message, our team will get back to you shortly, thank you."
				);

				// const data = new FormData();
				// data.set("token", token);
				// data.set("fullname", values.fullname);
				// data.set("email", values.email);
				// data.set("phone", values.phone);
				// data.set("subject", values.subject);
				// data.set("message", values.message);

				// fetch("/api/contact", {
				// 	method: "POST",
				// 	body: data,
				// })
				// 	.then((resp) => resp.json())
				// 	.then((api) => {
				// 		if (api.success) {
				// 			resetForm();
				// 			setStatus(
				// 				"We have successfully received your message, our team will get back to you shortly, thank you."
				// 			);
				// 		} else {
				// 			setStatus(
				// 				"Something went wrong. We could not receive your request at the moment, please try again later."
				// 			);
				// 		}
				// 		recaptchaRef.reset();
				// 		setLoading(false);
				//		setIsVerified(false);
				// 	});
			}}>
			{({ values, errors, touched, setFieldValue, resetForm }) => (
				<Form>
					<table>
						<thead></thead>
						<tbody>
							<tr>
								<td width='50%'>
									<Field
										name='fullname'
										type='text'
										placeholder='Fullname *'
										className={
											" " +
											(errors.fullname && touched.fullname
												? " error"
												: "")
										}
									/>
								</td>
								<td>
									<Field
										name='email'
										type='email'
										placeholder='Email *'
										className={
											" " +
											(errors.email && touched.email
												? " error"
												: "")
										}
									/>
								</td>
							</tr>
							<tr>
								<td width='50%'>
									<Field
										name='phone'
										type='text'
										placeholder='Phone number *'
										className={
											" " +
											(errors.phone && touched.phone
												? " error"
												: "")
										}
									/>
								</td>
								<td>
									<Field
										name='subject'
										type='text'
										placeholder='Subject *'
										className={
											" " +
											(errors.subject && touched.subject
												? " error"
												: "")
										}
									/>
								</td>
							</tr>
							<tr>
								<td colSpan='2'>
									<Field
										component='textarea'
										maxLength='144'
										name='message'
										type='text'
										placeholder={
											"Enter your message here ..."
										}
										className={
											" " +
											(errors.message && touched.message
												? " error"
												: "")
										}
									/>
								</td>
							</tr>
							<tr>
								<td colSpan='2'>
									<br />
								</td>
							</tr>
							<tr>
								<td colSpan='2'>
									<ReCAPTCHA
										ref={recaptchaRef}
										size='normal'
										sitekey={RECAPTCHA_SITE_KEY}
										onChange={async (v) => {
											setIsVerified(true);
											setFieldValue("token", v);
										}}
									/>
								</td>
							</tr>
							<tr>
								<td colSpan='2'>
									<br />
								</td>
							</tr>
							<tr>
								<td colSpan='2'>
									<ButtonTextIcon
										title='Send Message'
										type='submit'
										disabled={loading || !isVerified}
										icon={
											!loading ? (
												<CiLogin />
											) : (
												<LoaderIcon />
											)
										}
									/>
								</td>
							</tr>
						</tbody>
					</table>
					{status && (
						<p
							className='status color-green font-regular'
							style={{ marginTop: "30px" }}>
							{status}
						</p>
					)}
				</Form>
			)}
		</Formik>
	);
};

export default ContactForm;
