import ButtonTextIcon from "../components/ui/ButtonTextIcon";
import { CiLogin } from "react-icons/ci";
import { NavLink } from "react-router-dom";
import CommonLanding from "../components/common/CommonLanding";
import CommonContent from "../components/common/CommonContent";

const NotFound = () => {
	return (
		<>
			<CommonLanding title='Page not found' />
			<CommonContent>
				<div className='page-not-found'>
					<div className='small-container mar-0-a'>
						<div className='t-a-c'>
							<h1 className='color-primary'>Error 404.</h1>
							<br />
							<hr className='sep mar-0-a' />
							<br />
							<h4 className='color-light f-w-300'>
								Page not found.
							</h4>
							<br />
							<p>
								The page you are looking for has either vanished
								or does not exist.
							</p>
							<p>Return home instead?</p>
							<br />
							<hr className='line' />
							<br />
							<div className='flex center-center'>
								<NavLink to='/'>
									<ButtonTextIcon
										title='Return Home'
										icon={<CiLogin />}
									/>
								</NavLink>
							</div>
						</div>
					</div>
				</div>
			</CommonContent>
		</>
	);
};

export default NotFound;
