import { NavLink } from "react-router-dom";
import ButtonTextIcon from "../ui/ButtonTextIcon";
import { CiLogin } from "react-icons/ci";
import { EXTERNAL_URL } from "../../utilities/constants";

const MissionVision = () => {
	return (
		<section
			className='mission-vision pos-rel'
			style={{ background: "#f5f9f9" }}>
			<section className='container'>
				<section className='row flex'>
					<section className='col-1-1 left'>
						<div className='txt'>
							<h2 className='color-primary'>Mission</h2>
							<br />
							<hr className='sep' />
							<br />
							<p>
								The mission of Rent-Savvy is to simplify the
								rental process for both tenants and landlords by
								offering a transparent, secure, and
								user-friendly platform.
							</p>
							<br />
							<p>
								We aim to create an efficient, stress-free
								experience, empowering users to find, rent, and
								manage properties with ease.
							</p>
							<br />
							<p>
								At Rent-Savvy, we are committed to innovation,
								customer satisfaction, and building trust in
								every step of the rental journey.
							</p>
							<div className='btn'>
								<NavLink to='/contact'>
									<ButtonTextIcon
										title='Contact Us'
										icon={<CiLogin />}
									/>
								</NavLink>
							</div>
						</div>
					</section>
					<section className='col-1-1 right'>
						<div className='txt'>
							<h2 className='color-primary'>Vision</h2>
							<br />
							<hr className='sep' />
							<br />
							<p>
								The vision of Rent-Savvy is to become the
								leading platform that transforms the rental
								market through innovation and trust.
							</p>
							<br />
							<p>
								We aspire to create a world where renting is
								seamless, accessible, and efficient for
								everyone.
							</p>
							<br />
							<p>
								By continually improving our platform and
								services, we aim to set a new standard in the
								industry, empowering tenants and landlords to
								connect and thrive with ease and confidence.
							</p>
							<div className='btn'>
								<a href={EXTERNAL_URL}>
									<ButtonTextIcon
										title='Register Now'
										icon={<CiLogin />}
									/>
								</a>
							</div>
						</div>
					</section>
				</section>
			</section>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='90.205'
				height='82.351'
				viewBox='0 0 90.205 82.351'
				className='svg-bottom'>
				<g transform='translate(-276.138 -252.079)'>
					<path
						d='M276.138,252.079V334.43h57.775s18.088-.5,24.874-10.241,12.908-27.337,0-41.4-31.156-30.71-31.156-30.71Z'
						fill='#5dc6b3'
					/>
					<path
						d='M290.953,334.43l43.512-.032a56.454,56.454,0,0,0,8.178-1.2,45.36,45.36,0,0,0,5.6-1.582v-32.64L320.021,269.53l-29.069,29.446Z'
						fill='#3b86b5'
					/>
					<path
						d='M276.138,298.387V269.279l44.181.244-28.911,28.864Z'
						fill='#1e4ccb'
					/>
				</g>
			</svg>
		</section>
	);
};

export default MissionVision;
