import CommonLanding from "../components/common/CommonLanding";
import ContactSection from "../components/contact/ContactSection";

const Contact = () => {
	return (
		<>
			<CommonLanding
				title='Contact'
				subtitle='Have something to share or a question on your mind?'
			/>
			<ContactSection />
		</>
	);
};

export default Contact;
