import { useState } from "react";

const Single = ({ data, num }) => {
	const [show, setShow] = useState(false);

	return (
		<div className='one'>
			<div
				className={`question transition ${show ? "question-open" : ""}`}
				onClick={() => setShow(!show)}>
				<p
					className={`f-w-600 color-primary _t ${
						show ? "_t-open" : ""
					}`}
					dangerouslySetInnerHTML={{
						__html: `<span>${num}.</span> ${data.title}`,
					}}></p>
				<svg
					className={`_icon ${show ? "_icon-open" : ""}`}
					xmlns='http://www.w3.org/2000/svg'
					width='24'
					height='15'
					viewBox='0 0 24 15'>
					<path
						d='M0,7.33,2.829,4.5,12,13.839,21.171,4.5,24,7.33,12,19.5Z'
						transform='translate(0 -4.5)'
					/>
				</svg>
			</div>
			{show && (
				<div className='answer'>
					<p
						dangerouslySetInnerHTML={{
							__html: data.description,
						}}></p>
				</div>
			)}
		</div>
	);
};

const QuestionAnswers = () => {
	const questions = [
		{
			title: "How do I register on Rent-Savvy.com?",
			description:
				"Simply click the 'Register Now' button and follow the prompts to create a free account.",
		},
		{
			title: "Are the apartment listings verified?",
			description:
				"Yes, all listings on Rent-Savvy.com are verified to ensure you are viewing legitimate properties.",
		},
		{
			title: "Is there a fee to use Rent-Savvy.com?",
			description:
				"No, browsing listings and registering on Rent-Savvy.com is completely free.",
		},
		{
			title: "What types of properties are available?",
			description:
				"We offer a wide range of rentals, including studios, apartments, and penthouses across Dubai.",
		},
		{
			title: "How do I contact the landlord or property manager?",
			description:
				"Once you’ve found a property you like, you can contact the landlord or property manager directly through the platform.",
		},
		{
			title: "What if I have issues during the rental process?",
			description:
				"Our customer support team is available to help resolve any issues or answer questions during the rental process.",
		},
	];

	return (
		<section className='faqs'>
			<div className='container'>
				<div className='top mar-0-a t-a-c'>
					<h2 className='color-primary'>
						Frequently Asked Questions
					</h2>
					<br />
					<hr className='sep mar-0-a' />
					<br />
					<p>
						Have questions? Find quick answers to common inquiries
						about rental process to property details, we’ve got you
						covered!
					</p>
				</div>
				<div className='questions mar-0-a'>
					{questions.map((q, i) => (
						<Single data={q} key={i} num={i + 1} />
					))}
				</div>
			</div>
		</section>
	);
};

export default QuestionAnswers;
