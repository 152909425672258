import { NavLink } from "react-router-dom";
import {
	EXTERNAL_URL,
	FACEBOOK_URL,
	INSTAGRAM_URL,
	LINKEDIN_URL,
	TWITTER_URL,
	VERSION,
	WHATSAPP_URL,
} from "../../utilities/constants";
import {
	FacebookIcon,
	InstagramIcon,
	LinkedinIcon,
	TwitterIcon,
	WhatsappIcon,
} from "../common/SocialIcons";

const Footer = () => {
	return (
		<footer>
			<div className='container'>
				<ul className='social'>
					<li>
						<a href={FACEBOOK_URL} rel='noreferrer' target='_blank'>
							<FacebookIcon />
						</a>
					</li>
					<li>
						<a href={TWITTER_URL} rel='noreferrer' target='_blank'>
							<TwitterIcon />
						</a>
					</li>
					<li>
						<a href={WHATSAPP_URL} rel='noreferrer' target='_blank'>
							<WhatsappIcon />
						</a>
					</li>
					<li>
						<a href={LINKEDIN_URL} rel='noreferrer' target='_blank'>
							<LinkedinIcon />
						</a>
					</li>
					<li>
						<a
							href={INSTAGRAM_URL}
							rel='noreferrer'
							target='_blank'>
							<InstagramIcon />
						</a>
					</li>
				</ul>
				<br />
				<ul className='menu'>
					<li>
						<NavLink
							className='transition t-t-u font-montserrat'
							to='/contact'>
							Contact Us
						</NavLink>
					</li>
					<li>
						<NavLink
							className='transition t-t-u font-montserrat'
							to='/privacy-policy'>
							Privacy Policy
						</NavLink>
					</li>
					<li>
						<NavLink
							className='transition t-t-u font-montserrat'
							to='/terms'>
							Terms of Use
						</NavLink>
					</li>
					<li>
						<a
							className='transition t-t-u font-montserrat'
							href={EXTERNAL_URL}>
							Register Now
						</a>
					</li>
				</ul>
				<br />
				<p className='t-a-c small color-white'>
					Copyright &copy;2024
					<br /> <NavLink to='/'>Rent-Savvy</NavLink> - All Rights
					Reserved.
				</p>
				<br />
				<p className='t-a-c color-white small' style={{ opacity: 0.2 }}>
					Version v{VERSION}
				</p>
			</div>
		</footer>
	);
};

export default Footer;
