import LandingImage from "../../images/pages/home/landing-bg.jpg";

const CommonLanding = ({ title, subtitle }) => {
	return (
		<section id='common-page'>
			<section
				className='landing cover-img'
				style={{
					backgroundImage: `url(${LandingImage})`,
				}}>
				<div className='container'>
					<div className='row flex center-center'>
						<div className='txt t-a-c'>
							<h1 className='color-white'>{title}</h1>
							{subtitle && (
								<div>
									<br />
									<hr />
									<br />
									<h5 className='color-white f-w-300 l-h-1-5'>
										{subtitle}
									</h5>
								</div>
							)}
						</div>
					</div>
				</div>
			</section>
		</section>
	);
};

export default CommonLanding;
