import { createContext, useContext, useState } from "react";
import Auth from "../components/layout/Auth";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
	const [auth, setAuth] = useState(false);

	return (
		<AuthContext.Provider
			value={{
				auth,
				setAuth,
			}}>
			<>{auth ? <>{children}</> : <Auth />}</>
		</AuthContext.Provider>
	);
};

const useAuth = () => {
	const context = useContext(AuthContext);
	if (!context) {
		throw new Error("Auth Provider error.");
	}
	return context;
};

export { AuthProvider, useAuth };
