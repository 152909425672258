import CommonContent from "../components/common/CommonContent";
import CommonLanding from "../components/common/CommonLanding";

const Terms = () => {
	return (
		<>
			<CommonLanding title='Terms of Use' />
			<CommonContent>
				<div className='small-container mar-0-a'>
					<h4 className='color-primary'>Terms</h4>
				</div>
			</CommonContent>
		</>
	);
};

export default Terms;
