import {
	CiBadgeDollar,
	CiBookmarkCheck,
	CiCircleCheck,
	CiSettings,
	CiViewList,
} from "react-icons/ci";

const WhyChooseUsSection = () => {
	const reasons = [
		{
			title: "Verified Listings",
			description:
				"At Rent-Savvy.com, all apartment listings are thoroughly verified, ensuring you only see legitimate and trustworthy options.",
			icon: <CiCircleCheck />,
		},
		{
			title: "Wide Range of Properties",
			description:
				"Whether you're looking for a high-end apartment or a budget-friendly rental, we offer diverse listings to meet your needs.",
			icon: <CiViewList />,
		},
		{
			title: "Expert Guidance",
			description:
				"Our team of experts is here to assist you at every step, offering insights and advice on finding the right apartment in Dubai.",
			icon: <CiBadgeDollar />,
		},
		{
			title: "User-Friendly Platform",
			description:
				"With a simple and intuitive interface, finding your perfect apartment has never been easier.",
			icon: <CiBookmarkCheck />,
		},
		{
			title: "Seamless Process",
			description:
				"From browsing to booking a viewing, we streamline the rental process to save you time and hassle.",
			icon: <CiSettings />,
		},
	];

	return (
		<section className='why-choose-us'>
			<div className='container'>
				<div className='row flex'>
					<div className='col-1-3 left'>
						<h2 className='color-primary'>Why Choose Us</h2>
						<br />
						<hr className='sep' />
						<br />
						<p>
							At Rent-Savvy, we’re dedicated to simplifying the
							rental process for both tenants and landlords. With
							our user-friendly platform, you can easily find the
							perfect rental or manage your property with
							confidence.
						</p>
						<br />
						<p>
							Our tailored solutions ensure transparency,
							security, and efficiency, making the rental
							experience smoother for everyone.
						</p>
						<br />
						<p>
							Choose Rent-Savvy for trusted listings, reliable
							service, and a commitment to your rental success!
						</p>
					</div>
					<div className='col-2-3 right'>
						<div className='all'>
							{reasons.map((r, i) => (
								<div className='one' key={i}>
									<div className='icon'>{r.icon}</div>
									<div className='t'>
										<h6 className='color-primary'>
											{r.title}
										</h6>
										<p className='l-h-1-2'>
											{r.description}
										</p>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default WhyChooseUsSection;
