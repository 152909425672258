import { ParallaxProvider } from "react-scroll-parallax";
import HeroSection from "../components/home/HeroSection";
// ====== Option 2
import Welcome from "../components/home/Welcome";
// ====== Option 2
import Marquee from "../components/home/Marquee";
import Introduction from "../components/home/Introduction";
import QuickIntro from "../components/about/QuickIntro";
import Video from "../components/how-it-works/Video";
import WhyChooseUsSection from "../components/why-choose-us/WhyChooseUsSection";
import HowItWorksSection from "../components/how-it-works/HowItWorksSection";
import QuestionAnswers from "../components/faq/QuestionAnswers";
import ContactSection from "../components/contact/ContactSection";

const Home = () => {
	return (
		<ParallaxProvider>
			<HeroSection />
			{/* <Welcome /> */}
			<Marquee />
			<Introduction />
			<QuickIntro showButton={true} />
			<Video />
			<WhyChooseUsSection />
			<HowItWorksSection />
			<QuestionAnswers />
			<ContactSection />
		</ParallaxProvider>
	);
};

export default Home;
