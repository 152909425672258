import "./css/globals.scss";
import "./css/base.scss";
import "./css/animations.scss";
import "./css/pages.scss";
import "./css/responsive.scss";
import { AuthProvider } from "./context/auth";
import { Route, Routes } from "react-router-dom";
import WithLayout from "./hoc/withLayout";
import Home from "./pages/Home";
import About from "./pages/About";
import WhyChooseUs from "./pages/WhyChooseUs";
import HowItWorks from "./pages/HowItWorks";
import FAQs from "./pages/FAQs";
import Contact from "./pages/Contact";
import NotFound from "./pages/NotFound";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Terms from "./pages/Terms";

const App = () => {
	return (
		<AuthProvider>
			<Routes>
				<Route
					path='/'
					element={
						<WithLayout>
							<Home />
						</WithLayout>
					}
				/>
				<Route
					path='/about'
					element={
						<WithLayout>
							<About />
						</WithLayout>
					}
				/>
				<Route
					path='/about-us'
					element={
						<WithLayout>
							<About />
						</WithLayout>
					}
				/>
				<Route
					path='/why-choose-us'
					element={
						<WithLayout>
							<WhyChooseUs />
						</WithLayout>
					}
				/>
				<Route
					path='/how-it-works'
					element={
						<WithLayout>
							<HowItWorks />
						</WithLayout>
					}
				/>
				<Route
					path='/faq'
					element={
						<WithLayout>
							<FAQs />
						</WithLayout>
					}
				/>
				<Route
					path='/faqs'
					element={
						<WithLayout>
							<FAQs />
						</WithLayout>
					}
				/>
				<Route
					path='/contact'
					element={
						<WithLayout>
							<Contact />
						</WithLayout>
					}
				/>
				<Route
					path='/contact-us'
					element={
						<WithLayout>
							<Contact />
						</WithLayout>
					}
				/>
				<Route
					path='/privacy-policy'
					element={
						<WithLayout>
							<PrivacyPolicy />
						</WithLayout>
					}
				/>
				<Route
					path='/terms'
					element={
						<WithLayout>
							<Terms />
						</WithLayout>
					}
				/>
				<Route
					path='*'
					element={
						<WithLayout>
							<NotFound />
						</WithLayout>
					}
				/>
			</Routes>
		</AuthProvider>
	);
};

export default App;
