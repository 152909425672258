import { NavLink, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import RentSavvyLogo from "../common/RentSavvyLogo";
import { MENU_ITEMS } from "../../utilities/constants";

const Header = () => {
	const { pathname } = useLocation();
	const [menu, setMenu] = useState(false);

	useEffect(() => {
		const onScroll = () => {
			const header = document.getElementById("header");
			if (window.scrollY > 148) {
				header.classList.add("fixed");
			} else {
				header.classList.remove("fixed");
			}
		};
		window.removeEventListener("scroll", onScroll);
		window.addEventListener("scroll", onScroll, { passive: true });
		return () => window.removeEventListener("scroll", onScroll);
	});

	useEffect(() => {
		routeChangeStart();
		window.scrollTo(0, 0);
	}, [pathname]);

	const openMenu = () => {
		const body = document.getElementsByTagName("body")[0];
		body.style.overflow = "hidden";
		document.getElementsByTagName("nav")[0].classList.add("open");
	};

	const closeMenu = () => {
		document.getElementsByTagName("nav")[0].classList.remove("open");
		const body = document.getElementsByTagName("body")[0];
		body.style.overflow = "auto";
	};

	useEffect(() => {
		menu ? openMenu() : closeMenu();
	}, [menu]);

	const routeChangeStart = () => {
		if (menu) {
			setMenu(false);
			closeMenu();
		}
	};

	return (
		<header id='header'>
			<div className='container'>
				<div className='flex row'>
					<div
						title='Menu'
						className={`menu-btn ${menu ? "menu-btn-open" : ""}`}
						onClick={() => setMenu(!menu)}>
						<span></span>
						<span></span>
						<span></span>
					</div>
					<div className='logo'>
						<NavLink to='/'>
							<RentSavvyLogo className='transition' />
						</NavLink>
					</div>
					<nav>
						<ul>
							{MENU_ITEMS.map((m, i) => (
								<li key={i}>
									<NavLink to={m.url}>{m.title}</NavLink>
								</li>
							))}
						</ul>
					</nav>
				</div>
			</div>
		</header>
	);
};

export default Header;
